@import url(https://fonts.googleapis.com/css?family=Lato:400,700);

:root {
  --blue: #72beae;
  --green: #a68bd5;
  --orange: #e38968;
  --gray: #848997;
}

.App-link {
  color: #61dafb;
}

body {
  margin: 0;
}

.chat-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  width: 30%;
  height: 100vh;
  margin: auto;
  padding: 0;
  border-radius: 5px;
}

img {
  float: left;
}

.about {
  float: left;
  margin-top: 5px;
  padding-left: 8px;
}

ul {
  margin: 0;
  padding: 0;
}

#message-to-send {
  overflow: hidden;
}

.other-message p {
  margin-top: 1rem;
}

.svg-gradient {
  background-size: 100% auto;
}

.chat-header {
  width: 100%;
  color: white;
  background: rgba(58, 77, 188, 1);
  padding: 5px;
  border-radius: 5px 5px 0 0;
  height: 69px;
  z-index: 10;
}

.chat-header img {
  float: left;
}

.chat-about {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 6px;
}

.chat-with {
  font-weight: bold;
  font-size: 1rem;
}

.chat-num-messages {
  color: var(--gray);
}

.fa-star {
  float: right;
  color: #d8dadf;
  font-size: 1.3rem;
  margin-top: 12px;
}

.chat-history {
  z-index: 9;
  flex-grow: 1;
  background-color: white;
  padding: calc(30vw * 30 / 100) 30px 0px 30px;
  overflow-y: scroll;
}

.chat-history li {
  list-style-type: none;
}

.message-data {
  margin-bottom: 15px;
}

.message-data-time {
  color: #c5c5c5;
  padding-left: 6px;
  font-family: monospace;
  font-size: 0.8rem;
}

.message {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  color: rgb(229, 229, 229);
  padding: 2px 15px;
  line-height: 26px;
  font-size: 1rem;
  border-radius: 7px;
  margin-bottom: 30px;
  width: 90%;
  position: relative;
  text-align: left;
}

.message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #edf1f3;
  border-width: 10px;
  margin-left: -10px;
}

.my-message {
  width: 70%;
  padding: 20px;
  background: #edf1f3;
  color: rgb(61, 61, 61);
}

.other-message {
  width: 70%;
  float: right;
  background: linear-gradient(90deg, rgba(41, 119, 210, 1) 0%, rgba(76, 168, 231, 1) 100%);
}

.other-message:after {
  border-bottom-color: white;
  display: none;
  left: 93%;
}

.chat-message {
  z-index: 10;
  padding: 10px;
  display: flex;
  background-color: #ffffff;
  border-top: 1px solid rgb(145, 145, 145);
}

.chat-message input {
  width: 90%;
  border: none;
  padding: 5px 10px;
  font: 1rem "Lato", Arial, sans-serif;
  margin-bottom: 10px;
  border-radius: 5px;
  height: 35px;
}

.chat-message input:focus {
  outline: none;
}

.fa-file-o,
.fa-file-image-o {
  font-size: 1rem;
  color: gray;
  cursor: pointer;
}

#wave {
  position: absolute;
  top: 69px;
}

.chat-message button {
  float: right;
  color: var(--blue);
  font-size: 1rem;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: transparent;
}

.online {
  color: var(--green);
}

.offline {
  color: var(--orange);
}

.me {
  color: var(--blue);
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  content: " ";
  clear: both;
  height: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #ee6353;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.a {
  float: right;
  width: 50%;
  background-color: #c26060;
  color: white;
  padding: 6px;
  font-size: 0.8rem;
  line-height: 2ch;
  border-radius: 5px;
  font-family: monospace;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@media only screen and (min-width: 800px) {
  body {
    padding: 20px;
  }

  .chat-container {
    height: 96vh;
  }
}

@media only screen and (max-width: 1300px) {

  .chat-container {
    width: 50%;
  }

  .chat-history {
    padding-top: calc(50vw * 30 / 100)
  }
}

@media only screen and (max-width: 1024px) {
  .chat-container {
    width: 80%;

  }

  .chat-history {
    padding-top: calc(80vw * 30 / 100)
  }
}

@media only screen and (max-width: 600px) {
  .chat-container {
    width: 100%;
    position: relative;

  }

  .my-message,
  .other-message {
    width: 85%;
  }

  .chat-history {
    padding-top: calc(100vw * 30 / 100);
    padding-bottom: 50px;
  }

  .chat-message {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}

.picture-hero {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  z-index: -1;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  margin-top: 20px;
}

.main__container {
  width: 48%;
  height: 400px;
  margin-bottom: 1rem;
}

.main__container.black {
  background-color: rgba(0, 0, 0, 0.5);
}

.main__container.white {
  background-color: rgba(255, 255, 255, 0.5);
}

.main__disclaimer {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.main__disclaimer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35rem;
  max-width: 100%;
  padding: 1rem;
  text-align: center;
}

.main__disclaimer-title {
  font-family: "Arial";
  color: #ffffff;
}

.main__disclaimer-button {
  padding: 0.5rem 2rem;
  background-image: linear-gradient(90deg, #137382 0%, #00cccc 51%, #137382);
  background-size: 200% auto;
  color: #ffffff;
  border: 0;
  transition: 0.2s;
  font-size: 1.2rem;
  text-transform: uppercase;
  cursor: pointer;
}

.main__disclaimer-button:hover {
  background-position: 100%;
  transition: 0.2s;
}

.main__disclaimer-button:focus {
  outline: 0;
}

.blurred {
  filter: blur(15px);
}

.fade {
  transform: scale(0);
  transition: 0.4s;
}

.fade:not(.show) {
  z-index: -1;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(182, 182, 182);
}

::-webkit-scrollbar-thumb {
  background: #457ed3;
}