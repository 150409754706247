main{
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	padding: 20px;
}

#recorder {
	padding: 10px;
	/* background-color: rgb(204, 204, 238); */
}

#voice-divider {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;

}


.wave-wrapper{
	position: relative;
	height: 24rem;
	width: 24rem;
	overflow: hidden;
	border-radius: 50%;
	/* background-color: #d4fcfd; */
}

.wave{
	transition: all 0.5s ease-in-out;
	position: absolute;
	top: 90%;
	left: -50%;
	width: 48rem;
	height: 48rem;
	background-color: #2C4F86;
	border-radius: 35%;
	animation-name: waves;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	&.one{
		animation-duration: 5000ms;
		opacity: 0.4;
	}

	&.two{
		background-color: #70C7CB;
		animation-duration: 7000ms;
	}
	
	&.three{
		animation-duration: 10000ms;
	}
}


.record-main {
	width: 300px;
	margin: 20px;
	background-color: #b1c9f1;
	padding: 10px;
}
.record-main p {
	margin: 5px;
	text-align: center;
}
.record-buttons {
	display: flex;
	
	/* flex-flow: column; */
}
.record-buttons button {
	margin: 5px;
	width: 100%;
	height: 40px;
}

@keyframes waves{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
