/* Start Base Styles */
html {
  font-size: 100%;
}

:root {
  /* Colors */
  --clr-primary: hsl(235, 81%, 60%);
  --clr-white: hsl(0, 0%, 100%);
  --clr-black: hsl(0, 0%, 0%);
  --clr-red: hsl(355, 100%, 60%);
  --clr-gray: hsl(0deg 0% 35%);
  --clr-saffron: hsl(43, 93%, 64%);
  --clr-turquoise: hsl(161, 86%, 56%);
  --clr-lightBlue: hsl(234, 73%, 90%);
  --clr-lightRead: hsl(350, 75%, 89%);
  --clr-lightSaffron: hsl(52, 56%, 92%);
  --clr-lightTurquoise: hsl(167, 67%, 88%);
  --clr-lightGray: hsl(0deg 0% 44%);
  --clr-body: hsl(230, 43%, 97%);
  --clr-hero: hsl(222, 68%, 95%);
  --clr-overlay: hsla(0, 0%, 0%, 0.5);
  --clr-scrollbarThumb: hsl(234, 100%, 80%);
  --clr-social-icon: hsl(210, 25%, 98%);
  --clr-dribbble: hsl(330, 80%, 67%);
  --clr-facebook: hsl(220, 46%, 48%);
  --clr-twitter: hsl(203, 89%, 53%);
  --clr-youtube: hsl(0, 100%, 50%);

  /* Font */
  --ff: 'Rubik', sans-serif;
  /* Font size */
  --fs-biggest: 4.375rem;
  --fs-bigger: 3rem;
  --fs-big: 1.5rem;
  --fs-bold: 1.25rem;
  --fs-medium: 1.125rem;
  --fs-normal: 1rem;
  /* Font weight */
  --fw-black: 900;
  --fw-bold: 700;
  --fw-semiBold: 600;
  --fw-medium: 500;
  --fw-regular: 400;

  /* Header height (mobile & desktop) */
  --header-height-m: 4rem;
  --header-height-d: 6rem;

  /* Scrollbar */
  --scrollbar-width: 0.4375rem;

  /* Z-index */
  --z-fixed: 100;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scrollbar-width: var(--scrollbar-width);
  scrollbar-color: var(--clr-scrollbarThumb) var(--clr-white);
}

body {
  font-family: var(--ff);
  font-size: var(--fs-normal);
  background-color: var(--clr-hero);
  color: var(--clr-black);
}

main>section {
  /* padding-top: clamp(2rem, 10vw, 5rem); */
  padding-bottom: clamp(2rem, 10vw, 5rem);
}

h1,
h2,
h3 {
  font-weight: var(--fw-bold);
}

p {
  font-size: var(--fs-medium);
  font-weight: var(--fw-regular);
  line-height: 1.5625rem;
}

ul {
  list-style: none;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

button {
  border: none;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-track {

  border-radius: 0.625rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;

}

/* Selection */
::selection {
  color: var(--clr-white);
  background-color: var(--clr-primary);
}

/* Focus */
:focus,
:focus-visible {
  outline: 0.125rem solid var(--clr-black);
  outline-offset: 0.125rem;
}

:focus:not(:focus-visible) {
  outline: none;
}

:active {
  outline: none;
}

/* container */
.container {
  max-width: 71.25rem;
  margin: auto;
}

.container--px {
  padding-left: 1rem;
  padding-right: 1rem;
}
.main {
  width: 95%;
}
@media only screen and (max-width: 800px) {
  .container--px {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
}


/* Screen reader only (: */
.visually-hidden {
  position: absolute;
  left: -100vw;
  width: 0.0625rem;
  height: 0.0625rem;
  white-space: nowrap;
  overflow: hidden;
}

.flex {
  display: flex;
}

/* hide Scrollbar */
@media screen and (max-width: 40em) {
  .scroll-hide {
    overflow: hidden;
  }
}

/* Call to action */
a.cta,
.cta {
  font-size: 1rem;
  font-weight: var(--fw-bold);
  border: 0.15rem solid transparent;
  transition: all 300ms ease-in-out;
}

/* Cta primary */
a.cta--primary,
.cta--primary {
  display: inline-block;
  background-color: var(--clr-primary);
  color: var(--clr-white);
  padding: 10px 15px;
  border-radius: 0.1875rem;
}

/* .cta--primary:nth-child(1) {
    background-color: #4caf50;
  } */

a.cta--primary:hover,
.cta--primary:hover, #readmore:hover {
  opacity: 0.8;
  background-color: transparent;
  color: var(--clr-primary);
  border-color: currentColor;
}


#readmore{
  background-color: #4caf50;
}
/* Cta button */
.cta--btn {
  width: 2.1875rem;
  height: 2.1875rem;
  background-color: transparent;
  color: var(--clr-gray);
  border: 0.125rem solid transparent;
  border-color: inherit;
  border-radius: 0.3125rem;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.cta--btn:hover {
  background-color: currentColor;
}

.cta--btn_yellow {
  color: var(--clr-saffron);
}

.cta--btn_gray {
  color: var(--clr-gray);
}

.cta--btn_active {
  /* Active button */
  background-color: var(--clr-saffron);
  color: var(--clr-white);
  border-color: transparent;
}

.cta--btn_active:hover {
  background-color: transparent;
  color: var(--clr-saffron);
  border-color: currentColor;
}

.cta--btn-icon {
  width: 0.875rem;
  height: 0.6875rem;
  stroke: currentColor;
  transition: stroke 300ms ease-in-out;
}

.cta--btn:hover:not(.cta--btn_active) .cta--btn-icon {
  stroke: var(--clr-white);
}

/* Cta link */
.cta--link {
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
  font-size: var(--fs-medium);
  font-weight: var(--fw-bold);
  color: var(--clr-primary);
  padding: 0.3rem 0.5rem;
  margin: auto;
  margin-top: 4rem;
  transition: color 300ms ease-in-out;
}

.cta--link:hover {
  color: var(--clr-black);
}

.cta--link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 100%;
  background-color: var(--clr-lightRead);
  transition: width 300ms ease-in-out;
}

.cta--link:hover::before {
  width: 100%;
}

.cta--link-icon {
  width: 1.0625rem;
  height: 0.875rem;
  margin-left: 0.5rem;
  stroke: currentColor;
}

/* Primary subtitle*/
.subtitle-primary {
  font-size: clamp(var(--fs-medium), 2vw + 0.5rem, var(--fs-bold));
  font-weight: var(--fw-bold);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: var(--clr-primary);
  margin-bottom: clamp(0.5rem, 2vw + 0.5rem, 1.5rem);
}

/* Primary title */
.title-primary {
  /* margin-top: 100px; */
  font-size: clamp(var(--fs-big), 4vw + 0.5rem, var(--fs-bigger));
  font-weight: var(--fw-bold);
  margin-bottom: clamp(1rem, 3vw + 0.5rem, 3rem);
}

/* Disabled */
.disabled {
  visibility: hidden;
  opacity: 0;
}

/* End Base Styles */

/* Header */
.header {

  z-index: var(--z-fixed);
  width: 100%;
  height: var(--header-height-m);
  transition: all 300ms ease-in-out;
  background-color: transparent;
}

@media screen and (min-width: 40em) {
  .header {
    height: var(--header-height-d);
    background-color: var(--clr-hero);
  }
}

/* Header active */
.header--active {
  transition-duration: 0ms;
  background-color: var(--clr-white);
}

/* Header container */
.header__container {
  height: 100%;
}

/* Navbar */
.navbar {
  position: relative;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: inherit;
}

/* Brand */
.navbar__brand {
  font-size: clamp(var(--fs-big), 4vw + 0.5rem, 2rem);
  font-weight: var(--fw-black);
  color: var(--clr-black);
}

/* Toggle menu button */
.navbar__toggle-menu {
  background-color: transparent;
  cursor: pointer;
}

@media screen and (min-width: 40em) {
  .navbar__toggle-menu {
    display: none;
  }
}

/* Hamburger */
.toggle-menu__hamburger {
  position: relative;
  width: 2.2rem;
  padding: 0.1rem;
}

.hamburger__item {
  width: 100%;
  height: 0.25rem;
  background-color: var(--clr-black);
  border-radius: 1rem;
  transition: width 500ms ease-in-out;
}

.hamburger__item:not(:last-child) {
  margin-bottom: 0.31rem;
}

/* Hamburger animation */
.toggle-menu--active .hamburger__item:first-child {
  width: 70%;
}

.toggle-menu--active .hamburger__item:nth-child(2) {
  width: 90%;
}

.toggle-menu--active .hamburger__item:last-child {
  width: 55%;
}

/* Menu */
@media screen and (max-width: 39.9375em) {

  /* 639px */
  .navbar__menu {
    position: fixed;
    top: -100%;
    left: 0;
    z-index: -1;
    width: 100%;
    background-color: var(--clr-white);
    text-align: center;
    padding: 2rem 0;
    visibility: hidden;
    transition: all 500ms ease-in-out;
  }

  /* Menu open */
  .navbar__menu--open {
    visibility: visible;
    top: var(--header-height-m);
  }
}

.menu__item:not(:last-child) {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 40em) {
  .menu__item {
    display: inline-block;
  }

  .menu__item:not(:last-child) {
    margin: 0 0.6rem;
  }
}

.menu__link {
  position: relative;
  font-weight: var(--fw-medium);
  color: var(--clr-black);
  padding: 0 0.3rem;
  transition: opacity 300ms ease-in-out;
}

.menu__link:not(.cta)::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.4rem;
  width: 0;
  height: 0.25rem;
  border-radius: 1rem;
  background-color: var(--clr-red);
  transition: width 300ms ease-in-out;
}

/* Hover effect */
.menu__link:hover {
  opacity: 0.8;
}

.menu__link:hover::before {
  width: 100%;
}

/* Active link */
.menu__link--active {
  font-weight: var(--fw-bold)
}

.menu__link--active::before {
  width: 100%;
}

/* Overlay */
.header__overlay {
  position: fixed;
  inset: 0;
  z-index: -10000;
  background-color: var(--clr-overlay);
  visibility: hidden;
  opacity: 0;
  transition: all 500ms ease-in-out;
}

@media screen and (max-width: 40em) {
  .header__overlay--open {
    visibility: visible;
    opacity: 1;
  }
}


@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}
/* body {
  background: #1c8ef9 !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
} */

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}


/* Main */
.main {
  position: relative;
}

/* #4caf50 */
/* @media screen and (min-width: 40em){
    .main {
      padding-top: var(--header-height-d);
    }
  } */

/* Hero section */
.hero {
  margin-bottom: 10rem;
  /* position: relative;
  height: calc(70vh - var(--header-height-m)); */
  background-color: var(--clr-hero);
}

@media screen and (max-width: 40em) {
  .hero {
    /* height: auto; */
    background-color: transparent;
  }
}

.team__container{
  margin-top: 100px;
}

.julie {
  width: 70%;
  border-radius: 20px;
  margin: auto;
}

/* Hero container */
.hero__container {
  height: 100%;
  flex-direction: column;
  justify-content: center;

  margin-top: 40px;
}

@media screen and (max-width: 400px) {

  .julie {
    width: 95%;
  }
  .hero__container{
    margin-top: 100px;
  }
  /* .team__container{
    margin-top: 200px;
  } */
  .hero__cta{
    margin-top: 10px;
  }
}

@media screen and (min-width: 40em) {
  .hero__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}

.hero__text {
  width: min(30rem, 100%);
}

.hero__title {
  font-size: clamp(3rem, 6vw + 0.5rem, var(--fs-biggest));
  margin-bottom: 0.8rem;
}

.hero__title-underline {
  border-bottom: 0.25rem solid var(--clr-red);
}

.hero__paragraph {
  margin-bottom: 1rem;
}

.hero__image-wrapper {
  display: none;
}

@media screen and (min-width: 40em) {
  .hero__image-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

.hero__image {
  width: 70%;
}

/* Partners Section */
.partners {
  text-align: center;
}

.partners__title {
  font-size: clamp(var(--fs-medium), 2vw + 0.5rem, var(--fs-bold));
  font-weight: var(--fw-regular);
  color: var(--clr-gray);
  margin-bottom: clamp(1rem, 3vw + 0.5rem, 2.1875rem);
}

.partners__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(10rem, 100%), 1fr));
  gap: 1rem;
  align-items: center;
}

.partners__item {
  transition: none 300ms ease-in-out;
  transition-property: transform, opacity;
}

.partners__item:hover {
  transform: translateY(-0.1rem);
  opacity: 0.8;
}

.partner__img {
  width: 8.125rem;
  height: 3.125rem;
  object-fit: contain;
}

/* Features */
/* .features {} */

/* Features container */
.features__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(30rem, 100%), 1fr));
  gap: 2rem;
}

.features__text {
  max-width: 20.625rem;
}

.features__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14.5rem, 1fr));
  gap: 2rem;
}

.feature__icon-wrapper {
  width: 4.375rem;
  height: 4.375rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  margin-bottom: 1.5rem;
}

.feature__icon {
  width: 2.4375rem;
  height: 2.4375rem;
  fill: none;
}

.feature--1 .feature__icon-wrapper {
  background-color: var(--clr-lightBlue);
}

.feature--1 .feature__icon {
  stroke: var(--clr-primary);
}

.feature--2 .feature__icon-wrapper {
  background-color: var(--clr-lightRead);
}

.feature--2 .feature__icon {
  stroke: var(--clr-red);
  fill: var(--clr-red);
}

.feature--3 .feature__icon-wrapper {
  background-color: var(--clr-lightTurquoise);
}

.feature--3 .feature__icon {
  stroke: var(--clr-turquoise);
}

.feature--4 .feature__icon-wrapper {
  background-color: var(--clr-lightSaffron);
}

.feature--4 .feature__icon {
  stroke: var(--clr-saffron);
}

.feature__title {
  font-size: var(--fs-big);
  font-weight: var(--fw-medium);
  margin-bottom: 0.625rem;
}

.feature__paragraph {
  color: var(--clr-lightGray);
}

/* Portfolio */
.portfolio {
  text-align: center;
}

.portfolio__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(21.875rem, 100%), 1fr));
  justify-content: center;
  gap: 1.8rem;
}

.portfolio__card {
  position: relative;
  border-radius: 1.25rem;
}

.card__image {
  width: 100%;
  height: 25rem;
  object-fit: cover;
  border-radius: inherit;
}

.card__content {
  margin-top: 1.5rem;
  text-align: left;
}

.card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.card__title {
  font-size: var(--fs-big);
}

.card__description {
  padding-right: 1rem;
  color: var(--clr-lightGray);
}

.portfolio__cta {
  margin-top: clamp(1rem, 5vw + 0.5rem, 3rem);
}

/* Testimonial */
.testimonial {
  position: relative;
}

.testimonial::before {
  content: '';
  position: absolute;
  inset: 0;
  background-image: url('https://raw.githubusercontent.com/alawe45/Html-Css-Js-game-agency-project/d88374b98d5815d165f08b61f6678358a6c06fd0/images/map.svg');
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.1;
  box-shadow: 0 11.25rem 3.75rem var(--clr-body) inset;
}

.testimonial__container {
  position: relative;
}

.testimonial__header {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.testimonial__text {
  margin-right: 1rem;
}

.testimonial__controls {
  gap: 0.3rem;
}

.testimonial__wrapper {
  position: relative;
  overflow: hidden;
}

.testimonial__slider {
  width: 300%;
  gap: clamp(0.1rem, 2vw, 1rem);
  transition: all 500ms ease-in-out;
}

.testimonial__slide {
  position: relative;
  max-width: 28.75rem;
  background-color: var(--clr-white);
  border-radius: 0.625rem;
  padding: 1.8125rem 1.5rem;
}

.testimonial__slide-header {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1.1rem;
}

.testimonial__slide-data {
  align-items: center;
  margin-right: 1rem;
  flex-wrap: wrap;
}

.testimonial__slide-img {
  width: 3.875rem;
  height: 3.875rem;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.testimonial__slide-name {
  margin-bottom: 0.5rem;
}

.testimonial__slide-subtitle {
  color: var(--clr-lightGray);
}

.testimonial__slide-stars {
  padding: 1rem 0 0 0;
}

.testimonial__description {
  font-size: 1rem;
}

.testimonial__footer {
  text-align: center;
}

/* Team */
/* .team {
  } */

.team__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 21.875rem), 1fr));
  gap: 1.5rem;
}

.team__member {
  position: relative;
  border-radius: 1.25rem;
  display: flex;
  flex-flow: column;
}

.member__image {
  width: 100%;
  /* height: 26.25rem; */
  margin: auto;
  /* object-fit: cover; */
  /* object-position: center; */
  border-radius: 20px;
}

.member__info {
  text-align: center;
}


.member__designation {
  font-size: var(--fs-medium);
  color: var(--clr-lightGray);
}

/* Footer */
.footer {
  background-color: var(--clr-white);
  padding-top: clamp(2rem, 10vw, 5rem);
  padding-bottom: clamp(2rem, 10vw, 5rem);
}

.footer__container {
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}

.footer__header {
  flex-direction: column;
  justify-content: space-between;
}

.footer__inner {
  flex-wrap: wrap;
  gap: 1rem clamp(2rem, 7vw, 6.25rem);
}

.footer__brand {
  display: inline-block;
  width: max-content;
  font-size: clamp(var(--fs-big), 4vw + 0.5rem, 2rem);
  font-weight: var(--fw-black);
  color: var(--clr-black);
}

.footer__social-icons {
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 2rem;
}

.social-icons__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 3.125rem;
  height: 3.125rem;
  font-size: 1.75rem;
  background-color: var(--clr-social-icon);
  color: var(--clr-gray);
  border-radius: 0.625rem;
  transition: none 300ms ease-in-out;
  transition-property: opacity, color, box-shadow;
}

.social-icons__link--dribbble:hover {
  color: var(--clr-dribbble);
  border-color: currentColor;
}

.social-icons__link--facebook:hover {
  color: var(--clr-facebook);
  border-color: currentColor;
}

.social-icons__link--twitter:hover {
  color: var(--clr-twitter);
  border-color: currentColor;
}

.social-icons__link--youtube:hover {
  color: var(--clr-youtube);
  border-color: currentColor;
}

.social-icons__link:hover {
  opacity: 0.8;
}

.list__title {
  font-size: var(--fs-bold);
  font-weight: var(--fw-semiBold);
  margin-bottom: clamp(1rem, 5vw, 1.5rem);
}

.list__item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.list__link {
  display: inline-block;
  font-weight: var(--fw-regular);
  font-size: var(--fs-medium);
  color: var(--clr-lightGray);
  transition: transform 300ms ease-in-out;
}

.list__link:hover {
  transform: translateX(0.2rem);
}

.footer__text {
  color: var(--clr-gray);
}

/* :D */
